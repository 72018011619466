<template>
  <div class="managerList">
    <div class="tab">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        :class="['item', { active: requestData.orderStatus == item.key }]"
        @click="selectMem(item, index)"
      >
        <span>{{ item.label }} </span>
      </div>

      <div class="kinds" @click="isKind">
        <div class="img"><img src="./img/shaixuan.png" alt="" /></div>
        <div class="txt">{{ requestData.orderTypeText }}</div>
      </div>
    </div>
    <div class="secTab">
      <div
        v-for="(item, index) in areaOptions"
        :key="index"
        :class="['item', { active: requestData.area == item.value }]"
        @click="selectSecMem(item, index)"
      >
        <span>{{ item.text }} </span>
      </div>
    </div>
    <div class="main">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
        :commonOperaParams="commonOperaParams"
      >
        <div
          class="li"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="li-t">
            <div class="li-tl">{{ item.orderTitle }}</div>
            <div :class="['li-tr', 'te' + item.orderStatus]">
              {{ statusTypeMap[item.orderStatus] }}
            </div>
          </div>
          <div class="li-b">
            <div class="li-bl"><img :src="item.imageUrl" alt="" /></div>
            <div class="li-br">
              <div class="h4">{{ item.orderDisc }}</div>
              <div class="date">{{ item.createTime }}</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <v-picker
      :columns="workTypeOptions"
      :valueShow="workTypePickShow"
      valueKey="text"
      :defaultIndex="0"
      @clickOverlay="workTypePickShow = false"
      @cancel="workTypePickShow = false"
      @confirmPicker="workTypePickOnOk"
    />
  </div>
</template>

<script>
import { statusTypeMap } from "../map";
import {
  getOrderInfoPageListUrl,
  getDictOpsUrl,
  getOrderTypeAllListUrl,
  getAreaCommunityUnitAllListUrl,
} from "./api";
export default {
  name: "managerList",
  data() {
    return {
      workTypePickShow: false,
      statusTypeMap,
      finishedText: "没有更多了",
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      requestData: {
        role: 0,
        orderType: "",
        orderTypeText: "类别",
        area: "",
        curPage: 1,
        pageSize: 10,
        orderStatus: "2,3",
      },
      tabList: [
        { label: "全部", key: "2,3" },
        { label: "已退回", key: "3" },
      ],
      type: "热门活动",
      listData: [],
      workTypeOptions: [],
      areaOptions: [],
    };
  },
  mounted() {
    this.getOrderTypeAllList();
    this.getAreaCommunityUnitAllList();
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    role() {
      return this.$store.state.role;
    },
  },
  methods: {
    getAreaCommunityUnitAllList() {
      this.$axios.get(getAreaCommunityUnitAllListUrl).then((res) => {
        if (res && res.code === 200) {
          let arr = Array.isArray(res.data) ? res.data : [];
          arr.forEach((ele) => {
            ele.text = ele.areaName;
            ele.value = ele.areaId;
          });
          this.areaOptions = arr;
          this.areaOptions.unshift({
            text: "全部",
            value: "",
          });
        }
      });
    },
    getOrderTypeAllList() {
      this.$axios.get(getOrderTypeAllListUrl).then((res) => {
        if (res && res.code === 200) {
          let arr = Array.isArray(res.data) ? res.data : [];
          arr.forEach((ele) => {
            ele.text = ele.typeName;
            ele.value = ele.typeId;
            if (ele.orderTypeDetailList) {
              ele.orderTypeDetailList.forEach((element) => {
                element.text = element.typeDetailName;
                element.value = element.typeDetailId;
              });
              ele.children = ele.orderTypeDetailList;
            }
          });
          this.workTypeOptions = arr;
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        name: "lawEnforcement-managerDetail",
        query: {
          orderId: item.orderId,
        },
      });
    },
    isKind() {
      this.workTypePickShow = true;
    },
    workTypePickOnOk(value) {
      if (!value) return;
      this.workTypeOptions.forEach((ele) => {
        if (ele.text == value[0]) {
          ele.children.forEach((i) => {
            if (i.text == value[1]) {
              this.requestData.orderType = i.value;
              this.requestData.orderTypeText = i.text;
            }
          });
        }
      });
      this.workTypePickShow = false;
      this.init();
    },
    getDicts(dictType, prop) {
      this.$axios.get(getDictOpsUrl, { params: { dictType } }).then((res) => {
        if (res && res.code === 200) {
          res.data = Array.isArray(res.data) ? res.data : [];
          this[prop] = res.data.map((item) => ({
            text: item.dictName,
            value: item.dictCode,
          }));
          //   if (dictType == 1) {
          //     this.workTypeOptions.unshift({
          //       text: "全部",
          //       value: "",
          //     });
          //   }
          if (dictType == 2) {
            this.areaOptions.unshift({
              text: "全部",
              value: "",
            });
          }
        }
      });
    },
    init() {
      this.finished = false;
      this.requestData.curPage = 1;
      this.listData = [];
      this.getList();
    },
    selectSecMem(item, index) {
      this.requestData.area = item.value;
      this.init();
    },
    selectMem(item, index) {
      this.requestData.orderStatus = item.key;
      this.init();
    },
    getList() {
      let params = {
        area: this.requestData.area,
        orderStatus: this.requestData.orderStatus,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        orderType: this.requestData.orderType,
        role: this.role,
        userId: this.userId,
      };
      this.$axios.post(`${getOrderInfoPageListUrl}`, params).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    onLoad() {
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.managerList {
  min-height: 100%;
  background: #fafafaff;
  box-sizing: border-box;
  .tab {
    display: flex;
    justify-content: space-around;
    padding: 40px 180px 18px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    background: #ffffff;
    font-weight: 400;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 40px;
    span {
      font-weight: 400;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 40px;
    }
    .active {
      span {
        font-weight: 700;
        font-size: 32px;
        color: #000000;
        line-height: 44px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: -12px;
          left: 0;
          width: 114px;
          height: 12px;
          background: linear-gradient(
            270deg,
            #3d7bfa 0%,
            rgba(112, 178, 253, 0) 100%
          );
          border-radius: 6px;
        }
      }
    }
    .kinds {
      display: flex;
      align-items: center;
      position: absolute;
      right: 38px;
      top: 20px;
      bottom: 0;
      .img {
        width: 28px;
        height: 28px;
        margin-right: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        font-weight: 400;
        max-width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 24px;
        color: rgba(51, 51, 51, 0.5);
        line-height: 28px;
      }
    }
  }
  .secTab {
    padding: 8px 30px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 120px;
    background: #ffffff;
    font-weight: 400;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 40px;
    .item {
      display: inline-block;
      margin: 0 20px 15px 20px;
    }
    span {
      font-weight: 400;
      font-size: 28px;
      color: rgba(51, 51, 51, 0.5);
      line-height: 40px;
    }
    .active {
      span {
        font-weight: 700;
        font-size: 32px;
        color: #000000;
        line-height: 44px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: -12px;
          left: 0;
          right: 0;
          margin: auto;
          width: 30px;
          height: 8px;
          background: #3d7bfa;
          border-radius: 6px;
        }
      }
    }
  }
  .main {
    padding: 270px 30px 0;
    .li {
      height: 268px;
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 20px;
      padding: 0 34px;
      box-sizing: border-box;
      .li-t {
        padding: 36px 0 18px;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
        .li-tl {
          font-weight: 700;
          font-size: 32px;
          color: #333333;
          line-height: 44px;
          position: relative;
          padding-left: 34px;
          &::before {
            content: "";
            width: 12px;
            height: 12px;
            background: #3d7bfa;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
        .li-tr {
          width: 120px;
          height: 40px;
          background: rgba(255, 206, 111, 0.2);
          border-radius: 4px;
          font-weight: 700;
          font-size: 24px;
          color: #ffce6f;
          line-height: 40px;
          text-align: center;
        }
        .te0 {
        }
        .te1 {
          width: 120px;
          height: 40px;
          background: rgba(61, 123, 250, 0.2);
          border-radius: 4px;
          font-weight: 700;
          font-size: 24px;
          color: #3d7bfa;
          line-height: 40px;
          text-align: center;
        }
        .te2 {
          width: 120px;
          height: 40px;
          background: rgba(61, 123, 250, 0.2);
          border-radius: 4px;
          font-weight: 700;
          font-size: 24px;
          color: #3d7bfa;
          line-height: 40px;
          text-align: center;
        }
        .te3 {
          width: 120px;
          height: 40px;
          background: rgba(239, 78, 78, 0.2);
          border-radius: 4px;
          font-weight: 700;
          font-size: 24px;
          color: #ef4e4e;
          line-height: 40px;
          text-align: center;
        }
      }
      .li-b {
        margin-top: 16px;
        display: flex;
        align-items: center;
        .li-bl {
          width: 210px;
          height: 118px;
          margin-right: 14px;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .li-br {
          .h4 {
            font-weight: 700;
            font-size: 26px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 36px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .date {
            font-weight: 400;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
            line-height: 34px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
